<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="vendors"
        sort-by="name"
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Vendors</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        New Item
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.code" label="Kode" :readonly="editedIndex>-1" @keydown.space.prevent @change="validateCode"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.name" label="Nama"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.contactperson" label="Nama Kontak"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.phone" label="Telpon"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.email" label="Email" @keydown.space.prevent></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.address" label="Alamat"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea v-model="editedItem.policy" label="Kebijakan Vendor" outlined></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <!-- <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon> -->
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
</v-card>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Kode', align: 'start', sortable: false, value: 'code',
        },
        { text: 'Nama', value: 'name' },
        { text: 'Kontak', value: 'contactperson' },
        { text: 'Telpon', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        contactperson: '',
        phone: '',
        email: '',
        address: '',
        policy: '',
      },
      defaultItem: {
        name: '',
        contactperson: '',
        phone: '',
        email: '',
        address: '',
        policy: '',
      },
      vendors: [],
      keyword: '',
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    //   vendors() {
    //       return this.$store.state.vendor.objs
    //   }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      keyword(val) {
        if (!val) val = ''
        this.vendors = this.$store.state.vendor.objs.filter((el) => {
          console.log(val)
          // return el.name == "Trail Hambalang" //.includes(val)
          return el.name.toLowerCase().includes(val.toLowerCase()) ||
              el.contactperson.toLowerCase().includes(val.toLowerCase()) ||
              el.address.toLowerCase().includes(val.toLowerCase())
        })
      }

    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$store.dispatch('vendor/getObjs').then(response => {
            this.vendors = response
        })
      },
      validateCode(val) {
        this.editedItem.code = val.replace(/\s/g,'_')
      },

      editItem (item) {
        this.editedIndex = this.vendors.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.vendors.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.vendors.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.vendors[this.editedIndex], this.editedItem)
        } else {
          this.vendors.push(this.editedItem)
        }
        delete this.editedItem._id
        console.log(this.editedItem)
        
        this.$store.dispatch('vendor/postObj', this.editedItem).then(response => {
            console.log(response)
            this.close()
        }, err => {
            console.log(err)
            this.close()
        })
      },
    },
  }
</script>